import {
  CellClickedEvent,
  CellEditingStoppedEvent,
  CellEditingStartedEvent,
  BodyScrollEvent,
  ColumnResizedEvent,
  NavigateToNextCellParams,
  CsvExportParams,
  ExcelExportParams,
  ExcelCell,
  AgGridEvent,
  ProcessCellForExportParams,
  GridReadyEvent,
  DisplayedColumnsChangedEvent,
  CellPosition,
} from '@ag-grid-community/core';

import type { CellKeyDownEvent, NewColumnsLoadedEvent, RowDataUpdatedEvent } from '@ag-grid-community/core';
import { clamp, isEmpty, isNil, isNumber } from 'lodash';
import moment from 'moment';
import DataGrid from './DataGrid';
import { Renderer } from './Renderer';
import numbro from 'numbro';

export const MOVE_DIRECTION_LOOKUP = {
  // gets row, column change based on arrow direction
  ['ArrowUp']: [-1, 0],
  ['ArrowDown']: [1, 0],
  ['ArrowLeft']: [0, -1],
  ['ArrowRight']: [0, 1],
  ['w']: [-1, 0],
  ['s']: [1, 0],
  ['a']: [0, -1],
  ['d']: [0, 1],
  ['Enter']: [1, 0],
};

export function onCellClicked(this: DataGrid, event: CellClickedEvent): void {
  if (this.props.extraAgGridProps?.suppressRowClickSelection != true) {
    event.node.setSelected(true);
  }

  if (this.props.onCellClicked && !this.props.getAlternateContextMenu) {
    this.props.onCellClicked(event);
  } else if (this.props.onCellClicked && this.props.getAlternateContextMenu) {
    this.props.onCellClicked(event);
    this.noRenderState.leftOrRightClick = 'left';
    this.showWorklist(event);
  }
}

export function getMainMenuItems() {
  return [
    'pinSubMenu', // Submenu for pinning. Always shown.
    'autoSizeThis', // Auto-size the current column. Always shown.
    'autoSizeAll', // Auto-size all columns. Only shown on non-transposed views.
    'resetColumns', // Reset column details. Always shown.
    'expandAll', // Expand all groups. Only shown if grouping by at least one column.
    'contractAll', // Contract all groups. Only shown if grouping by at least one column.
  ];
}
export function onCellEditingStopped(this: DataGrid, event: CellEditingStoppedEvent): void {
  if (this.props.onCellEditingStopped) {
    this.props.onCellEditingStopped(event);
  }
}
export function onCellEditingStarted(this: DataGrid, event: CellEditingStartedEvent): void {
  if (this.props.onCellEditingStarted) {
    this.props.onCellEditingStarted(event);
  }
}
export function onBodyScroll(this: DataGrid, event: BodyScrollEvent): void {
  if (this.props.onBodyScroll) {
    this.props.onBodyScroll(event);
  }
}
export function onColumnResized(this: DataGrid, event: ColumnResizedEvent): void {
  if (event.finished && event.api) {
    event.api.redrawRows();
  }
}

export function getCellFromRowCol(
  this: DataGrid,
  rowChange: number,
  colChange: number,
  previousCellDef: CellPosition
): CellPosition {
  if (!this.noRenderState.columnApi || !this.noRenderState.gridApi) {
    throw new Error('No grid apis found when moving, this shouldnt happen');
  }

  const colChangeLookup = {
    [-1]: this.noRenderState.columnApi.getDisplayedColBefore(previousCellDef.column),
    0: previousCellDef.column,
    1: this.noRenderState.columnApi.getDisplayedColAfter(previousCellDef.column),
  };

  // this gives us the last ag-grid row index (this returns rows.length)
  const lastRowIndex = this.noRenderState.gridApi.getDisplayedRowCount() - 1;

  // this can return null on the left/right side of the screen, but the type doesn't reflect that
  // fallback to the previous col if we get a null for the next one
  const nextColumn = colChangeLookup[colChange] ? colChangeLookup[colChange] : previousCellDef.column;
  const nextRow = clamp(previousCellDef.rowIndex + rowChange, 0, lastRowIndex);

  return {
    rowPinned: undefined,
    rowIndex: nextRow,
    column: nextColumn,
  };
}

export function onCellKeyDown(this: DataGrid, e: CellKeyDownEvent) {
  const event = (e.event as unknown) as React.KeyboardEvent;
  const isEditingCell = this.noRenderState.gridApi?.getEditingCells();
  if (event && MOVE_DIRECTION_LOOKUP[event.key] && this.noRenderState.gridApi && isEmpty(isEditingCell)) {
    e.event?.preventDefault();
    e.event?.stopPropagation();
    const key = event.key;
    const previousCell = this.noRenderState.gridApi.getFocusedCell();
    let [moveRow, moveColumn] = MOVE_DIRECTION_LOOKUP[key];
    if (!isNil(previousCell)) {
      if (event.shiftKey) {
        // flip everything when shift is held
        moveRow = moveRow * -1;
        moveColumn = moveColumn * -1;
      }

      const nextCellDef = this.getCellFromRowCol(moveRow, moveColumn, previousCell);
      this.noRenderState.gridApi.clearRangeSelection();
      this.noRenderState.gridApi.clearFocusedCell();
      this.noRenderState.gridApi.setFocusedCell(nextCellDef.rowIndex, nextCellDef.column, undefined);
      this.noRenderState.gridApi.addCellRange({
        rowStartIndex: nextCellDef.rowIndex,
        rowEndIndex: nextCellDef.rowIndex,
        columnStart: nextCellDef.column,
        columnEnd: nextCellDef.column,
      });
    }
  }
}

export function handleNavigateToNextCell(this: DataGrid, _params: NavigateToNextCellParams): CellPosition {
  // this is basically a well-typed noop()
  // all keyboard movement functionality is handled by onCellKeyDown()
  if (!this.noRenderState.gridApi) {
    throw new Error('No columnapi found when moving, this shouldnt happen');
  }
  return this.noRenderState.gridApi.getFocusedCell()!;
}

export function onExcelExport(this: DataGrid) {
  const exportOptions = this.props.exportOptions;
  const processCellOverride = exportOptions && exportOptions.processCellOverride;
  const showGroupTitlesExcel = exportOptions && exportOptions.showGroupTitlesExcel;
  let excelOptions: ExcelExportParams = {
    sheetName: 'S5 Assortment Export',
    processCellCallback: function (params: ProcessCellForExportParams) {
      const { column, value, node } = params;
      const colId = column.getColId();
      let cellRenderer = column.getColDef()['renderer'] || column.getColDef()['cellRenderer'];

      if (isNil(cellRenderer) && isNumber(value)) {
        cellRenderer = node ? node.data.formatter : undefined;
      }

      const renderer = typeof cellRenderer === 'string' ? cellRenderer : '';
      const renderFn = renderer ? Renderer[renderer] : undefined;
      const isFirstColumn = colId === 'ag-Grid-AutoColumn';

      // If no renderer  & value not null - return the original value
      if (!cellRenderer && !isNil(value)) {
        return `'${value}`; // Prepend quote to preserve exactly format, including leading zeros
      }

      if (isFirstColumn && showGroupTitlesExcel) {
        // return the group name for all the group column cells if configured
        return node?.data.group[0];
      }

      // Handle group columns correctly for export when nested
      if (isFirstColumn && node?.groupData) {
        // Only show the current level value
        return node.groupData[colId];
      }

      if (processCellOverride) {
        const possibleNewValue = processCellOverride(params);
        if (possibleNewValue) {
          return possibleNewValue;
        }
      }

      if (exportOptions && exportOptions.excelRendererObj && renderer !== '') {
        const excelFormat = exportOptions.excelRendererObj[renderer];
        if (excelFormat) {
          return value;
        }
      }
      return !isFirstColumn && renderFn ? renderFn(value) : value;
    },
  };

  if (this.props.exportOptions) {
    const { customHeader, fileName } = this.props.exportOptions;
    const header: ExcelCell[] = [{
      data: {
        type: 'String',
        value: customHeader as string | null,
      },
      mergeAcross: 5,
    }];

    excelOptions = {
      ...excelOptions,
      prependContent: customHeader ? [{
        cells: header
      }] : undefined,
      fileName: fileName && fileName + ' at ' + moment().format('YYYY-MM-DD hh|mmA'),
    };
  }

  if (this.noRenderState.gridApi) {
    this.noRenderState.gridApi.exportDataAsExcel(excelOptions);
  }
}

export function onCsvExport(this: DataGrid) {
  const processCellOverride = this.props.exportOptions && this.props.exportOptions.processCellOverride;
  let csvOptions: CsvExportParams = {
    processCellCallback: function (params: ProcessCellForExportParams) {
      const { column, value } = params;
      const colId = column.getColId();
      const cellRenderer = column.getColDef()['renderer'] || column.getColDef()['cellRenderer'];
      const renderer = typeof cellRenderer === 'string' ? cellRenderer : '';
      const renderFn = renderer ? Renderer[renderer] : undefined;
      const isColorColumn = colId.indexOf('band_color') >= 0;

      // Change HEX color codes to N/A.
      if (isColorColumn) {
        return 'N/A';
      }

      if (processCellOverride) {
        const possibleNewValue = processCellOverride(params);
        if (possibleNewValue) {
          return possibleNewValue;
        }
      }
      return renderFn ? renderFn(value) : value;
    },
  };

  if (this.props.exportOptions) {
    const { customHeader, fileName } = this.props.exportOptions;
    csvOptions = {
      ...csvOptions,
      prependContent: customHeader && customHeader + '\n',
      fileName: fileName && fileName + ' at ' + moment().format('YYYY-MM-DD hh|mmA'),
    };
  }

  if (this.noRenderState.gridApi) {
    this.noRenderState.gridApi.exportDataAsCsv(csvOptions);
  }
}

export function onGridReady(this: DataGrid, event: GridReadyEvent) {
  this.noRenderState.gridApi = event.api;
  this.noRenderState.columnApi = event.columnApi;
  event.api.updateGridOptions({ columnDefs: this.processColDefs() });
  const { defaultShownValues } = this.noRenderState;
  // when applicable apply default sorts/filters falling back to favorite values
  if (!this.props.loaded) {
    setTimeout(() => this.noRenderState.gridApi?.showLoadingOverlay());
  }
  if (defaultShownValues) {
    event.api.setFilterModel(defaultShownValues);
  }
  if (!isEmpty(this.props.gridFilterModel)) {
    event.api.setFilterModel(this.props.gridFilterModel);
  }
  if (!isEmpty(this.props.gridSortModel)) {
    event.columnApi.applyColumnState({ state: this.props.gridSortModel });
  }

  if (this.props.isPrintMode) {
    event.api.setDomLayout('print');
  }

  if (this.props.onGridReady) {
    this.props.onGridReady(event);
  }

  // at times even though the data prop has been modified and updated via gridApi
  // the grid will still not render any data so this forces it on grid ready to refresh
  // this should be called after `this.props.onGridReady` so that parent components have their
  // gridApi when data is set, which may be needed to calculated side effects
  event.api.updateGridOptions({ rowData: this.props.data });
}

export function onNewColumnsLoaded(this: DataGrid, event: NewColumnsLoadedEvent) {
  if (this.props.onNewColumnsLoaded) {
    this.props.onNewColumnsLoaded(event);
  }
}

export function onDisplayedColumnsChanged(this: DataGrid, event: DisplayedColumnsChangedEvent) {
  if (this.props.onDisplayedColumnsChanged) {
    this.props.onDisplayedColumnsChanged(event);
  }
}

export function onFilterChanged(this: DataGrid, event: AgGridEvent) {
  const currentFilterModel = event.api.getFilterModel();
  if (this.props.storeGridFilterModel) {
    this.props.storeGridFilterModel(currentFilterModel);
  }
}

export function onSortChanged(this: DataGrid, event: AgGridEvent) {
  const currentSortModel = event.api.getColumnState();
  if (this.props.storeGridSortModel) {
    this.props.storeGridSortModel(currentSortModel);
  }
}

export function onRowDataUpdated(this: DataGrid, event: RowDataUpdatedEvent) {
  if (!isEmpty(this.props.gridFilterModel)) {
    event.api.setFilterModel(this.props.gridFilterModel);
  }
  if (!isEmpty(this.props.gridSortModel)) {
    event.columnApi.applyColumnState({ state: this.props.gridSortModel });
  }
}
