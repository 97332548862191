import { isEmpty, isNaN, isNil, isString } from 'lodash';
import { evaluate } from 'mathjs';
import limitedEvaluate, { mathScope } from 'src/services/configuration/MathJS.service';
import { IntegerEditorParams } from './IntegerEditor.types';

function processExpression(expression: string) {
  const evaluated = limitedEvaluate ? limitedEvaluate(expression, mathScope) : null;
  return !isNil(evaluated) ? evaluated : expression;
}

function isValueValid(val: number | null = null, tests?: string[]): boolean {
  const value = !Number.isNaN(Number(val)) ? Number(val) : val;
  const testsPassed = () => {
    if (tests && tests.length > 0) {
      if (val === null) {
        return tests.indexOf('NOT_NULLABLE') === -1;
      }

      const testMap: boolean[] = tests.filter((t) => t !== 'NOT_NULLABLE').map((test) => evaluate(test, { value }));
      if (testMap.every((test) => test === true)) {
        return true;
      } else {
        return false;
      }
    }
    return true;
  };
  const didTestsPass = testsPassed();

  return didTestsPass;
}

function isNumberValid(value: number | string | null, inputParams: IntegerEditorParams | undefined) {
  // add test to make sure it is a number
  let tests: string[] = [];
  const { valueTests, min, max, nullable } = inputParams ?? {};
  tests.push(`isNumeric(${value})`);

  if (!isNil(min)) {
    tests.push(`${value} >= ${min}`);
  }
  if (!isNil(max)) {
    tests.push(`${value} <= ${max}`);
  }
  if (nullable === false) {
    tests.push(`value != null`);
  }
  if (valueTests) {
    tests = tests.concat(valueTests);
  }

  // strings at this point should only be decimals in progress '2.'
  // need to return true for isNumberValid for decimals in progress
  return !isString(value) && !isValueValid(value, tests) ? false : true;
}

function formatInput(value: string): string | number {
  // don't strip values if potentially starting to type an expression or decimal
  // (i.e. '1.', '1.0', '-1+', '3-' is not stripped)
  const expressionInProgress = value.search(/^[\d+\-*/.()]*$/) >= 0;

  if (expressionInProgress) {
    return value;
  }

  // TODO: this may not be necessary any more with expressionInProgress check above
  // strips off invalid characters
  while (!Number.isFinite(parseFloat(value)) && value.length > 0) {
    value = value.slice(0, -1);
  }

  // will return 'NaN' for empty string or a non-decimal numeric value
  return parseFloat(value);
}

function processStateValue(
  stateValue: string | number | null,
  currentValue: string | number | null,
  inputParams: IntegerEditorParams | undefined
) {
  let processedStateValue;
  const internalValue = stateValue?.toString() || '';

  // outputs NaN for anything equation like
  // also prevents numbers from being handled in limitedEvaluate below (like 0) resulting in null
  const parsedInternalValue = Number(internalValue);

  if (isEmpty(internalValue)) {
    processedStateValue = null;
  } else if (isNaN(parsedInternalValue)) {
    processedStateValue = processExpression(internalValue);
  } else if (isString(stateValue)) {
    // in case user tries to submit value with decimalInProgress '2.', value is parsed as 2
    processedStateValue = parsedInternalValue;
  } else {
    processedStateValue = stateValue;
  }

  // 'unprocess' percentage value at the very end before running validations
  if (inputParams?.percent && (processedStateValue as unknown) !== '') {
    processedStateValue = processedStateValue / 100;
  }

  const isValid = isNumberValid(processedStateValue, inputParams);
  let finalValue;

  // nullable check is first to avoid issues if min/max is set but user is clearing selection
  // which would cause isValid to return false and reset to inital value

  if (inputParams?.nullable && (isNil(processedStateValue) || (processedStateValue as unknown) === '')) {
    finalValue = null;
  } else if (!isValid) {
    // new behavior here, no longer validate passedInt if invalid
    finalValue = currentValue;
  } else {
    // for not nullable, return 0
    finalValue = isNil(processedStateValue) ? 0 : processedStateValue;
  }

  return finalValue;
}

export { processExpression, processStateValue, isNumberValid, formatInput };
