import { defaultTo, flow, get, hasIn, isNil } from 'lodash';
import { connect } from 'react-redux';
import { AppThunkDispatch, AppState } from 'src/store';
import { ReceiptGridOwnProps, ReceiptGridValueProps, ReceiptGridDispatchProps } from './ReceiptGrid.types';

import { makePrintSensitive } from 'src/components/higherOrder/Print/PrintSenstive';
import { makePopoverSensitive } from '../AssortmentStyleDetailsPopover/AssortmentStyleDetailsPopover';
import {
  resetGroupBySelection,
  setGroupBySelection,
  setFloorsetSelection,
  updateConfigurableGridConfig,
  refreshConfigurableGridData,
} from 'src/components/ConfigurableGrid/ConfigurableGrid.slice';
import { bindActionCreators } from 'redux';
import { FabType, getfabProps, withFab } from '../higherOrder/withFab';
import { updateAssortmentPlan } from 'src/pages/AssortmentBuild/StyleEdit/StyleEdit.actions';
import { isViewDefnLoaded } from 'src/dao/tenantConfigClient';
import { isDataLoaded } from 'src/services/pivotServiceCache';
import { ConfigurableGrid } from './ReceiptGrid';
import {
  getViewDefnData,
  getGroupByDropdownProps,
  getConfigurableGridData,
  ConfigurableGridDataSelectorProps,
  getGroupedConfigurableGridData,
} from 'src/components/ConfigurableGrid/ConfigurableGrid.selectors';
import { ConfigurableGridColumnDef } from 'src/components/ConfigurableGrid/ConfigurableGrid.types';
import { submitPayload } from 'src/pages/AssortmentBuild/FlowSheet/FlowSheetByStyle.slice';
import { SubmitPayload, BasicPivotItem } from 'src/worker/pivotWorker.types';
import { AdornmentType } from 'src/services/configuration/codecs/viewdefns/literals';
import { AgFlatResult } from 'src/utils/Component/AgGrid/AgDataFormat';

interface ReceiptGridProps extends ReceiptGridValueProps, ReceiptGridOwnProps {}
function mapStateToProps(state: AppState, ownProps: ReceiptGridOwnProps): ReceiptGridProps {
  const showFlow = defaultTo(ownProps.showFlowStatus, true);
  const {
    viewDefnState,
    gridDataState: viewDataState,
    viewDefn,
    unmodifiedViewDefn,
  } = state.pages.assortmentBuild.configurableGrid;
  const configLoaded = isViewDefnLoaded(viewDefnState);
  const dataLoaded = isDataLoaded(viewDataState);

  const columnDefs = isNil(viewDefn) ? [] : (viewDefn.columns as ConfigurableGridColumnDef[]);
  const detailColumnDefs = isNil(viewDefn) ? [] : (viewDefn.detailColumns as ConfigurableGridColumnDef[]);
  const gridRowHeight: number = get(viewDefn, 'main.rowHeight', 30);
  const detailRowCountKey: string | null = get(viewDefn, 'main.detailRowCountKey', null);
  const massEditConfig = hasIn(viewDefn, 'massEditConfig') ? viewDefn.massEditConfig : undefined;
  const fetchDetails = hasIn(viewDefn, 'actions.fetchDetails') ? get(viewDefn, 'actions.fetchDetails') : undefined;
  const dataRules = hasIn(viewDefn, 'dataRules') ? viewDefn.dataRules : [];
  const showPublish = hasIn(viewDefn, 'showPublish') ? viewDefn.showPublish : undefined;
  const updateCoordinateMap = hasIn(viewDefn, 'updateCoordinateMap') ? viewDefn.updateCoordinateMap : undefined;
  const salesAdjustmentConfig = isNil(viewDefn) ? {} : viewDefn.salesAdjustment;
  const adornments: AdornmentType[] = get(viewDefn, 'adornments', []);

  const { dependentCalcs, companionSortOptions, defaultCompanionSortField, clientActionHandlers } = getViewDefnData(
    state
  );
  const groupByDropdownProps = getGroupByDropdownProps(state);
  const floorsetDropdownProps = undefined;
  const selectorProps: ConfigurableGridDataSelectorProps = {
    showFlowStatus: showFlow,
    leafId: '',
  };
  const groupedData: AgFlatResult | undefined = getGroupedConfigurableGridData(state, selectorProps);
  const ungroupedData: BasicPivotItem[] = getConfigurableGridData(state, selectorProps);
  const flatData: BasicPivotItem[] =
    isNil(groupedData) || isNil(groupByDropdownProps?.selection) ? ungroupedData : groupedData.agFlatTree;
  const treeColumnDefinition = isNil(groupedData) ? undefined : groupedData.treeColumnDefinition;
  const { fabType = FabType.none } = ownProps;
  const fabProps = getfabProps(state, fabType);

  return {
    ...ownProps,
    fetchDetails,
    flowStatus: showFlow ? state.subheader.flowStatus : [],
    showFlowStatus: showFlow,
    search: state.subheader.search || '',
    groupBySelection: state.pages.assortmentBuild.configurableGrid.groupBySelection,
    favoritesList: state.subheader.favoritesList || [],
    fab: fabProps,
    configLoaded,
    dataLoaded,
    columnDefs,
    detailColumnDefs,
    dataRules,
    massEditConfig,
    showPublish,
    updateCoordinateMap,
    dependentCalcs,
    gridRowHeight,
    detailRowCountKey,
    companionSortOptions,
    defaultCompanionSortField,
    unmodifiedViewDefn,
    configuratorViewDefn: viewDefn,
    clientActionHandlers,
    groupByDropdownProps,
    floorsetDropdownProps,
    data: flatData,
    topAttributesData: state.pages.assortmentBuild.configurableGrid.topAttributesData,
    salesAdjustmentConfig,
    adornments,
    treeColumnDefinition,
    viewDataState,
  };
}

function mapDispatchToProps(dispatch: AppThunkDispatch, _ownProps: ReceiptGridOwnProps): ReceiptGridDispatchProps {
  return {
    dispatch,
    ...bindActionCreators({ setGroupBySelection, resetGroupBySelection, setFloorsetSelection }, dispatch),
    onUpdateConfig: (config: any) => {
      dispatch(updateConfigurableGridConfig(config));
    },
    updateAssortmentPlan: () => {
      dispatch(updateAssortmentPlan('PlanQueue'));
    },
    onRefreshConfigurableGridData: () => {
      dispatch(refreshConfigurableGridData());
    },
    submitPayload: async (payload: SubmitPayload) => {
      await submitPayload(payload)();
    },
    addSelectedItemsToCart() {
      // do nothing for now
    },
    toggleSelections(_items: BasicPivotItem[]) {
      // implement when worklist selection is enabled in this component
    },
  };
}

const sensitiveView = flow(() => ConfigurableGrid, withFab, makePrintSensitive, makePopoverSensitive)();
export default connect(mapStateToProps, mapDispatchToProps)(sensitiveView);
